<template>
  <div class='d-flex justify-content-center align-items-center' style='height: calc(100vh - 250px)' v-if='!LoginCheck'>
    <div class='card' :class='$style.container'>
      <div class='text-dark font-size-32'>
        Giriş Yap
      </div>
      <div class='mt-2 mb-4'>
        Online Training Yönetim Paneli
      </div>
      <a-form
        :model='loginForm'
        :rules='rules'
        layout='vertical'
        class='mb-4'
        @finish='handleFinish'
      >
        <a-form-item name='email'>
          <a-input v-model:value='loginForm.email' placeholder='Email' />
        </a-form-item>
        <a-form-item name='password'>
          <a-input v-model:value='loginForm.password' placeholder='Password' type='password' />
        </a-form-item>
        <a-button type='danger' html-type='submit' class='text-center w-100 font-weight-bold' :loading='loading'>
          Giriş Yap
        </a-button>
      </a-form>
    </div>
  </div>
</template>
<script>
import store from 'store'
import { useStore } from 'vuex'
import jsonWebToken from 'jsonwebtoken'
import { computed, reactive } from 'vue'
import { notification } from 'ant-design-vue'
import { Account } from '../../../../services/api-service'

export default {
  name: 'GirisYap',
  setup: function() {
    const stored = useStore()
    const settings = computed(() => stored.getters.settings)

    const rules = {
      email: [
        {
          required: true,
          message: 'Geçerli Bir Mail Adresi Girin',
          trigger: 'change',
        },
      ],
      password: [
        {
          required: true,
          message: 'Lütfen Şifrenizi Girin',
          trigger: 'change',
        },
      ],
    }

    const loginForm = reactive({
      email: '',
      password: '',
    })

    const changeAuthProvider = value => {
      stored.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }

    return {
      settings,
      rules,
      loginForm,
      stored,
      changeAuthProvider,
    }
  },
  data() {
    const handleFinish = async (values) => {
      this.loading = true

      try {
        const { data } = await Account.login({ email: values.email, password: values.password })
        if (data && data.jwt) {
          const parsedJwt = jsonWebToken.decode(data.jwt, { complete: true })
          const userRole = parsedJwt.payload.auth

          if (userRole !== 'ROLE_SUPER_ADMIN' && userRole !== 'ROLE_ADMIN') {
            notification.error({
              message: 'Giriş Yapma Yetkiniz Bulunmamaktadır.',
            })

            throw new Error('User Role Error')
          }

          this.stored.commit('user/SET_STATE', {
            id: parsedJwt.payload.userId,
            name: 'Yönetici',
            email: parsedJwt.payload.sub,
            avatar: '',
            role: userRole,
            authorized: true,
          })

          store.set('accessToken', data.jwt)

          notification.success({
            message: 'Başarıyla Giriş Yapıldı!',
          })
        } else {
          notification.error({
            message: 'Token Hatası, Lütfen Yazılım Ekibiyle İletişime Geçiniz',
          })
        }
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    }

    return {
      handleFinish,
      LoginCheck: true,
      loading: false,
    }
  },
  async mounted() {
    const stored = useStore()
    const token = await store.get('accessToken')
    if (token) {
      const parsedJwt = jsonWebToken.decode(token, { complete: true })
      const userRole = parsedJwt.payload.auth

      if (userRole !== 'ROLE_SUPER_ADMIN' && userRole !== 'ROLE_ADMIN') {
        notification.error({
          message: 'Giriş Yapma Yetkiniz Bulunmamaktadır.',
        })

        this.LoginCheck = false
      } else {
        await stored.commit('user/SET_STATE', {
          id: parsedJwt.payload.userId,
          name: 'Yönetici',
          email: parsedJwt.payload.sub,
          avatar: '',
          role: userRole,
          authorized: true,
        })
      }
    } else {
      this.LoginCheck = false
    }
  },
}
</script>
<style lang='scss' module>
@import '../../../components/Auth/style.module.scss';
</style>
